<template>
  <v-dialog v-model="acceptDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="green">mdi-check</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center card-title__heading">
        <h2> {{ $t('Accept') }} {{ recordData.id }}</h2>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="4">
            <v-autocomplete :items="boxItems" readonly :label="$t('box')" color="#757575" item-text="name" item-value="id"
              v-model="financialOrderData.box" no-filter>
            </v-autocomplete>
          </v-col>
          <v-col md="4">
            <v-text-field :label="$t('user name')" readonly v-model="username"></v-text-field>
          </v-col>
          <v-col md="4">
            <v-menu v-model="dateDialog" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
              offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="financialOrderData.date" :label="$t('date')" :dark="$store.state.isDarkMode" prepend-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <!-- <v-date-picker
              readonly
                v-model="financialOrderData.date"
                @input="dateDialog = false"
              ></v-date-picker> -->
            </v-menu>
          </v-col>
          <v-col md="4">
            <v-text-field type="number" color="#757575" :label="$t('Value')" v-model="financialOrderData.amount"
              readonly></v-text-field>
          </v-col>
          <v-col md="4">
            <v-autocomplete :items="accountItems" :label="$t('hr.employee.check.account')" color="#757575" item-text="name" item-value="id"
              v-model="financialOrderData.account" no-filter readonly>
            </v-autocomplete>
          </v-col>
          <v-col md="4">
            <v-autocomplete :items="costCenterItems" :label="$t('cost center')" color="#757575" item-text="name" item-value="id"
              v-model="financialOrderData.cost_center" no-filter readonly>
            </v-autocomplete>
          </v-col>
          <v-col md="12">
            <v-text-field :label="$t('explain')" v-model="financialOrderData.desc" color="#757575"></v-text-field>
          </v-col>

          <v-col md="12">
            <v-text-field type="number" color="#757575" :label="$t('number')" v-model="financialOrderData.number"></v-text-field>
          </v-col>
          <v-col md="12" class="d-flex justify-end align-center">
            <!-- :disabled="disableBtn" -->
            <v-btn class="white--text" color="green" :loading="editBtnLoading" @click="editRecord"
              v-if="financialOrderData.action == 'pay'">قبول أمر دقع</v-btn>
            <v-btn class="white--text" color="green" @click="editRecord" :loading="editBtnLoading"
              v-if="financialOrderData.action == 'deposit'">قبول أمر قبض</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center"> </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import Notifications from "../../../../helpers/toast-notifications";
export default {
  data() {
    return {
      boxItems: [],
      accountItems: [],
      costCenterItems: [],
      editBtnLoading: false,
      acceptDialog: false,
      dateDialog: false,
      boxItems: [],
      boxesLoading: false,
      dialog: false,
      financialOrderData: {
        box: null,
        date: "",
        amount: null,
        account: null,
        desc: "",
        action: "",
        cost_center: null,
        number: null,
      },
      showIdDialog: false,
      idNumber: null,
      username: "",
    };
  },
  computed: {
    // disableBtn() {
    //   if (!this.financialOrderData.number) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  props: {
    recordData: Object,
  },

  methods: {
    async editRecord() {
      try {
        let requestBody = {
          number: this.financialOrderData.number,
          desc: this.financialOrderData.desc,
        };
        if (!this.financialOrderData.desc) {
          delete requestBody.desc;
        }
        if (!this.financialOrderData.number) {
          delete requestBody.number;
        }
        this.editBtnLoading = true;
        await axios.post(
          `/accounting/financial-order/${this.recordData.id}`,
          requestBody
        );
        Notifications(
          "تم القبول بنجاح",
          { timeout: 2000, rtl: true },
          "success"
        );
        this.$emit("recordUpdated");
        this.editDialog = false;
      } catch (err) {
      } finally {
        this.editBtnLoading = false;
      }
    },
  },
  created() {
    console.log("accept order record data", this.recordData);
    this.financialOrderData.box = this.recordData.box;
    this.financialOrderData.date = this.recordData.date;
    this.financialOrderData.amount = this.recordData.amount;
    this.financialOrderData.account = this.recordData.account;
    this.financialOrderData.cost_center = this.recordData.cost_center;
    this.financialOrderData.action = this.recordData.action;
    this.boxItems = [this.financialOrderData.box];
    this.accountItems = [this.financialOrderData.account];
    this.costCenterItems = [this.financialOrderData.cost_center];
    this.username = this.recordData.generator.username;
  },
};
</script>

<style></style>